<template>
  <div class="page page--center">
    <div class="page__container">
      <img class="page__container__logo" src="@/assets/svg/logo-99.svg" alt="Logo 99 Empresas">

      <div class="box__image">
        <img src="@/components/userRegistration/tips/img/foto.png" alt="" class="box__image__photo">
        <img src="@/components/userRegistration/tips/img/icone1.png" alt="" class="box__image__icon">
      </div>

      <h1 class="page__container__title">
        <span>Pronto, {{ userName }}!</span><br>
        O cadastro da empresa foi submetido com sucesso, agora basta conferir sua caixa de entrada para confirmá-lo.
      </h1>

      <div class="page__container__text">
        <p class="mb-6">O e-mail de confirmação do cadastro da empresa <strong>{{ companyName }}</strong> foi enviado para <strong>{{ userEmail }}</strong>. <span v-if="false">Está correto? Caso o email esteja incorreto, <a href="#" title="clique aqui para trocar">clique aqui para trocar</a>.</span></p>

        <div class="mb-4">
          <v-btn
            href="https://mail.google.com"
            title="Confirmar no meu Gmail"
            color="warning--gradient"
            depressed
            rounded
            target="_blank"
          >
            Confirmar no meu Gmail
          </v-btn>
        </div>

        <div class="mb-4">
          <v-btn
            href="https://mail.yahoo.com"
            target="_blank"
            title="Confirmar no meu Yahoo"
            color="warning--gradient"
            depressed
            rounded
          >
            Confirmar no meu Yahoo
          </v-btn>
        </div>

        <div class="mb-3">
          <v-btn class="mb-4"
            href="https://outlook.office365.com/mail/inbox"
            target="_blank"
            title="Confirmar no meu Outlook"
            color="warning--gradient"
            depressed
            rounded
          >
            Confirmar no meu Outlook
          </v-btn>
        </div>

        <p>Ou acesse sua caixa de entrada do email corporativo ou aplicativo padrão para recebimento.</p>

        <a href="https://99app.com/empresas" title="Voltar para a página do 99Empresas" class="text--small">
          Voltar para página da 99Empresas
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RegistrationSubmitted',
  metaInfo () {
    return {
      titleTemplate: this.routeTitle + ' | %s'
    }
  },
  computed: {
    ...mapState({
      userName: ({ prospect }) => prospect.data.name,
      userEmail: ({ prospect }) => prospect.data.corporateEmail,
      companyName: ({ prospect }) => prospect.data.companyName,
      prospect: ({ prospect }) => prospect.data
    }),

    routeTitle () {
      return this.$route.meta.title
    }
  },
  methods: {
    sendGTMDataLayer () {
      const { id = 'noinfo', paymentMethod } = this.prospect
      const getPaymentMethodName = (paymentMethod) => {
        const methods = {
          billet: 'boleto',
          creditCard: 'cartão de crédito'
        }

        return methods[paymentMethod] || 'noinfo'
      }

      this.$GTMDataLayer.send({
        event: 'cadastro-step4',
        'step-form': '4-validacao-do-email',
        'id-cadastro-hubspot': id,
        pagamento: getPaymentMethodName(paymentMethod)
      })
    }
  },
  mounted () {
    this.sendGTMDataLayer()
  }
}
</script>

<style lang="scss" scoped>
.page__container {
  position: relative;
}

.page__container__text {

  @media (min-width: 600px) {
    padding-right: 148px;
  }
}

.page__container__buttons {
  flex-direction: column;
  display: inline-flex;

  .v-btn {
    display: inline-flex;
    width: auto;
    flex: auto;
  }
}

.box__image {
  background: $warning-2;
  border-radius: 40px;
  display: none;
  padding: 30px 50px 30px 30px;
  position: absolute;
  right: -50px;
  top: 260px;
  z-index: 1;

  &:before {
    background: url('~@/components/userRegistration/tips/img/ellipse.png') no-repeat;
    background-size: cover;
    content: '';
    display: block;
    height: 186px;
    left: 70px;
    position: absolute;
    top: 15px;
    width: 126px;
    z-index: 0;
  }

  @media (min-width: 1200px) {
    display: inline-block;
  }
}

.box__image__photo {
  max-width: 160px;
  position: relative;
  z-index: 1;
}

.box__image__icon {
  height: 55px;
  left: 166px;
  position: absolute;
  top: 120px;
  z-index: 1;
}
</style>
